import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSearchBox from "../../Components/CustomSearch";
import TableContainer from "../../Components/TableContainer";
import { ApiList } from "../../Config/ApiList";
import AxiosInstance from "../../Config/AxiosInstance";
import { toast } from "react-toastify";
import moment from "moment/moment";
import useDebounce from "../../Components/useDebounce";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import PathList from "../../Common/PathList";
import { useNavigate } from "react-router-dom";
const SocialMedia = () => {
  const navigate = useNavigate();
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [socialMedia, setsocialMedia] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchUser, setSearchUser] = useState("");
  const debouncedSearch = useDebounce(searchUser, 500);
  const handlePageChange = (newValue) => {
    setPage(newValue);
  };
  //   const handleDelete = async (userId) => {
  //     Swal.fire({
  //       title: "Are you sure?",
  //       text: "You want to delete this influencer",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#46adf2",
  //       cancelButtonColor: "#5b5d5d",
  //       confirmButtonText: "Yes",
  //       cancelButtonText: "Cancel",
  //     }).then(async (result) => {
  //       if (result.isConfirmed) {
  //         try {
  //           const formData = new FormData();
  //           formData.append("id", userId);
  //           formData.append("status", -1);
  //           const res = await AxiosInstance.post(ApiList.DELETE_USER, formData);
  //           if (res?.data?.s) {
  //             toast.success(`Influencer Deleted Sucessfully`);
  //             getBusiness();
  //           }
  //           console.log(res);
  //         } catch (error) {
  //           console.log(error);
  //         }
  //       }
  //     });
  //   };
  const getSocialMedia = async () => {
    try {
      setIsLoading(true);
      const response = await AxiosInstance.get(ApiList.GET_SOCIAL_PENDING, {
        params: {
          count: page * pageSize,
          offset: pageSize,
          search: debouncedSearch,
          role: 2,
        },
      });
      if (response && response?.data?.s === 1) {
        const formattedUsers = response?.data?.r?.map((user, index) => ({
          rowid: index + 1 + page * pageSize,
          ...user,
          dob: moment(user?.dob).format("DD/MM/YYYY"),
        }));
        setsocialMedia(formattedUsers);
        setCount(response?.data?.c);
      } else {
        setsocialMedia([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      field: "rowid",
      headerName: "Sr.No",
      width: 100,
      align: "center",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "user_name",
      headerName: "User Name",
      width: 300,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (params) => {
        return params?.row?.user_name ? params?.row?.user_name : "--";
      },
    },
    {
      field: "name",
      headerName: "Platform",
      width: 200,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (params) => {
        return params?.row?.name ? `${params?.row?.name}` : "--";
      },
    },
    {
      field: "profile_link",
      headerName: "Profile Link",
      flex: 1,
      minWidth: 200,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (params) => {
        return params?.row?.profile_link
          ? `${params?.row?.profile_link}`
          : "--";
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 200,
      headerAlign: "center",
      headerClassName: "center",
      cellClassName: "center",
      renderCell: (params) => {
        if (params?.row?.is_approved === 1) {
          return (
            <Box
              sx={{
                width: "150px",
                display: "flex",
                justifyContent: "center",
                bgcolor: "#668B4B",
                color: "white",
                borderRadius: "8px",
              }}
            >
              <Typography sx={{ p: 1 }}>Veified</Typography>
            </Box>
          );
        } else if (params?.row?.is_approved === -1) {
          return (
            <Box
              sx={{
                width: "150px",
                display: "flex",
                justifyContent: "center",
                bgcolor: "#903C39",
                color: "white",
                borderRadius: "8px",
              }}
            >
              <Typography sx={{ p: 1 }}>Rejected</Typography>
            </Box>
          );
        } else if (params?.row?.is_approved === 0) {
          return (
            <Box
              sx={{
                width: "150px",
                display: "flex",
                justifyContent: "space-evenly",
                color: "white",
                borderRadius: "8px",
              }}
            >
              <IconButton
                onClick={() => handleBlockUnBlock(params?.row?.id, 1)}
                sx={{
                  bgcolor: "#668B4B",
                  "&:hover": { bgcolor: "#668B4B" },
                  borderRadius: "8px",
                }}
              >
                <CheckIcon sx={{ color: "white", fontSize: "18px" }} />
              </IconButton>
              <IconButton
                onClick={() => handleBlockUnBlock(params?.row?.id, -1)}
                sx={{
                  bgcolor: "#903C39",
                  "&:hover": { bgcolor: "#903C39" },
                  borderRadius: "8px",
                }}
              >
                <CloseIcon sx={{ color: "white", fontSize: "18px" }} />
              </IconButton>
            </Box>
          );
        }
        return "--";
      },
    },
    // {
    //   field: "Action",
    //   headerName: "Action",
    //   minWidth: 100,
    //   headerClassName: "center",
    //   cellClassName: "center",
    //   headerAlign: "center",
    //   renderCell: (params) => {
    //     return (
    //       <Box
    //         sx={{
    //           width: "150px",
    //           display: "flex",
    //           justifyContent: "space-evenly",
    //           color: "white",
    //           borderRadius: "8px",
    //         }}
    //       >

    //         <IconButton
    //           onClick={() => handleDelete(params?.row?.id)}
    //           disabled={params?.row?.status === -1 ? true : false}
    //         >
    //           <DeleteIcon
    //             sx={{
    //               color: params?.row?.status === -1 ? "grey" : "#903C39",
    //               fontSize: "28px",
    //             }}
    //           />
    //         </IconButton>
    //       </Box>
    //     );
    //   },
    // },
  ];
  const handleBlockUnBlock = async (userId, isStatus) => {
    Swal.fire({
      title: "Are you sure?",
      text:
        isStatus === -1
          ? "You want to Reject this Social Media"
          : "You want to Approve this Social Media",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const formData = new FormData();
          formData.append("id", userId);
          formData.append("is_approved", isStatus);
          const res = await AxiosInstance.post(
            ApiList.SOCIAL_MEDIA_APPROVE_REJECT,
            formData
          );
          if (res?.data?.s) {
            toast.success(
              `Social Media ${
                isStatus === -1 ? "Rejected" : "Approved"
              } Sucessfully !`
            );
            getSocialMedia();
          }
          console.log(res);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };
  useEffect(() => {
    getSocialMedia();
  }, [debouncedSearch, page]);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          overflow: "auto",
          p: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "600",
              color: "#668B4B",
            }}
          >
            Social Media
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              mt: 3,
            }}
          >
            <TableContainer
              sx={{
                height: { xs: "300px", md: "350px", xl: "600px" },
                width: "100%",
              }}
              rows={socialMedia}
              columns={columns}
              pageSize={pageSize}
              loading={isLoading}
              hideFooter={true}
              page={page}
              totalCount={count}
              handlePageChange={handlePageChange}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SocialMedia;
