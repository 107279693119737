import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomTextField from "../../Components/CustomTextField";
import CustomButton from "../../Components/CustomButton";
import CustomDropDown from "../../Components/CustomDropDown";
import AxiosInstance from "../../Config/AxiosInstance";
import { ApiList } from "../../Config/ApiList";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
const Notifications = () => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const menuList = [
    {
      id: 1,
      name: "All",
    },
    {
      id: 2,
      name: "Content Creators",
    },
    {
      id: 3,
      name: "Business",
    },
  ];
  const formik = useFormik({
    initialValues: {
      to: 1,
      title: "",
      message: "",
    },
    validationSchema: Yup.object().shape({
      to: Yup.string().required("Please select one*"),
      title: Yup.string().required("Please enter title*"),
      message: Yup.string().required("Please enter message*"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        setBtnLoading(true);
        const res = await AxiosInstance.post(
          `${ApiList.SEND_NOTIFICATIONS}?title=${values?.title}&message=${values?.message}&users=${values?.to}`
        );
        if (res && res?.data?.s === 1) {
          toast.success(
            `Notification sent sucessfully ${
              values?.to === 1
                ? "all."
                : values?.to === 2
                ? "all content creators."
                : values?.to === 3 && "all business."
            }`
          );
          resetForm();
          setLoading(false);
          setBtnLoading(false);
        }
        setLoading(false);
        setBtnLoading(false);
      } catch (error) {
        setLoading(false);
        setBtnLoading(false);
      }
    },
  });
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          overflow: "hidden",
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: { xs: "10px", md: "20px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "600",
              color: "#668B4B",
            }}
          >
            Notifications
          </Typography>
        </Box>

        <Box
          sx={{
            width: { xs: "350px", md: "450px" },
            borderRadius: "12px",
            bgcolor: "#f1f0f0",
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: { xs: "10px", md: "20px" },
          }}
        >
          <Box>
            <CustomDropDown
              title={"To"}
              value={formik.values.to}
              menuList={menuList}
              onChange={(e) => formik.setFieldValue("to",e?.target?.value)}
              sx={{ mt: 0.5 }}
              onBlur={formik.handleBlur}
              onKeyUp={formik.handleBlur}
            />
            {formik?.touched?.to && formik.errors.to ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: "400",
                  fontSize: { xs: "14px", sm: "14px" },
                  marginTop: "5px",
                }}
              >
                {formik?.errors?.to}
              </Typography>
            ) : null}
          </Box>
          <Box>
            <CustomTextField
              name="title"
              label={"Title"}
              value={formik?.values?.title}
              onBlur={formik.handleBlur}
              onKeyUp={formik.handleBlur}
              onChange={(e) => {
                const { value } = e.target;
                if (value.trimStart() !== value) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
              placeholder={"Enter title"}
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: "12px",
                  border: "none",
                  background: "#ffffff",
                  height: "50px",
                },
                mt: 0.5,
              }}
            />
            {formik?.touched?.title && formik.errors.title ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: "400",
                  fontSize: { xs: "14px", sm: "14px" },
                  marginTop: "5px",
                }}
              >
                {formik?.errors?.title}
              </Typography>
            ) : null}
          </Box>
          <Box>
            <CustomTextField
              name="message"
              value={formik?.values?.message}
              label={"Message"}
              multiline={true}
              rows={5}
              onChange={(e) => {
                const { value } = e.target;
                if (value.trimStart() !== value) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
              placeholder={"Enter Message"}
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: "12px",
                  border: "none",
                  background: "#ffffff",
                },
                mt: 0.5,
              }}
            />
            {formik?.touched?.message && formik.errors.message ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: "400",
                  fontSize: { xs: "14px", sm: "14px" },
                  marginTop: "5px",
                }}
              >
                {formik?.errors?.message}
              </Typography>
            ) : null}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomButton
              title={"Send"}
              type="submit"
              sx={{ borderRadius: "12px", width: "200px" }}
              onClick={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
              }}
              loading={btnLoading}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Notifications;
