export const ApiList = {
    //AUTH
    LOGIN:"/admin-sign-in",
    DASHBOARD_DETAILS:"/auth/user/dashboard",
    GET_USER_LIST:"/auth/user/get-users-list",
    APPROVE_REJECT:"/auth/user/approve-reject",
    GET_BY_ID:"/auth/user/get-details",
    DELETE_USER:"/auth/user/delete",
    ADD_CATEGORY:"/category/create",
    UPDATE_CATEGORY:"/category/update",
    GET_CATEGORY:"/category/get-list",
    SEND_NOTIFICATIONS:"/auth/notification/send",
    GET_SOCIAL_PENDING:"/auth/user/social-media-platform/get-pending-list",
    SOCIAL_MEDIA_APPROVE_REJECT:"/auth/user/social-media-platform/approve-reject"
    // FORGOT_PASSWORD:"/auth/forgot-password",
}